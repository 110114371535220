import React from 'react';
import Banner from '../../assets/Banner/itSolutionBanner.jpg';

import './ItSolutions.styles.scss';

const ItSolutions = () => {
    return (
        <div id='itsolutions'>
            <div id='container'>
                <div id="topImage">
                    <img src={Banner} alt="TopImage" />
                </div>
                <div id='content'>
                    <div className="intro">
                        <span className="title">Our service center helps our client in 4 different languages around the clock 7 days a week in 50+ countries around the world.</span>
                        <ul>
                            <li><p className="description">Efficient consulting answers to the questions provided by support line (phone/ email twebsite / Service Desk).</p></li>
                            <li><p className="description">Single point of contact for solving problems of support on different solutions.</p></li>
                            <li><p className="description">The possibility to attract professionals with expertise on specified products and technologies if necessary.</p></li>
                            <li><p className="description">Unified SLA.</p></li>
                            <li><p className="description">Service Management.</p></li>
                            <li><p className="description">Transparent reporting system.</p></li>
                            <li><p className="description">Quarterly business review.</p></li>
                            <li><p className="description">Full coverage of all time zones. 24x7 operation mode.</p></li>
                        </ul>
                        <span className="title">Benefits:</span>
                        <ul>
                            <li><p className="description">An opportunity to focus as much as possible on the core business.</p></li>
                            <li><p className="description">Guaranteed availability of IT services according to business requirements.</p></li>
                            <li><p className="description">The ability to quickly change IT infrastructure and resources for the dynamics of the business.</p></li>
                            <li><p className="description">Transparency of the service level for the business, reporting and parameters according to your requirements.</p></li>
                            <li><p className="description">Multilingual front line.</p></li>
                            <li><p className="description">Dedicated project manager.</p></li>
                            <li><p className="description">The ability to attract project experts to implement the planned changes in the IT infrastructure.</p></li>
                            <li><p className="description">Obtaining the maximum effect out of the products and solutions.</p></li>
                        </ul>
                    </div>
                    <div className='break' />
                </div>
            </div>
        </div>
    )
}

export default ItSolutions;