import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './component/header/header.component';
import Footer from './component/footer/footer.component';
import HomePage from './pages/homePage/homePage.component';
import AboutUs from './pages/aboutUs/aboutUs.component';
import ContactUs from './pages/contactUs/contactUs.component';
import Service from './pages/service/service.component';
import Product from './pages/product/product.component';
import './App.css';
//import Popup from './component/maintanancePopup/maintanancePopup.component';
import APInvoicesDOScanning from './pages/APInvoicesDOScanning/APInvoicesDOScanning.component';
import BookScanning from './pages/BookScanning/BookScanning.component';
import PatientMedicalRecordScanning from './pages/PatientMedicalRecordScanning/PatientMedicalRecordScanning.component';
import LegalDocumentScanning from './pages/LegalDocumentScanning/LegalDocumentScanning.component';
import DocumentImaging from './pages/DocumentImaging/DocumentImaging.component';
import MobileApplication from './pages/MobileApplication/MobileApplication.component';
import ITHostingServices from './pages/IT&HostingServices/IT&HostingServices.component';
import OnsiteScanning from './pages/OnsiteScanning/OnsiteScanning.component';
import QuestionnairePage from './pages/questionnaire/questionnaire.component';
import DocumentScanner from './pages/DocumentScanner/DocumentScanner.component';
import BookScanner from './pages/BookScanner/bookScanner.component';
import LargeFormatScanner from './pages/LargeFormatScanner/largeFormatScanner.component';
import MultifunctionPlotter from './pages/multifunctionPlotter/multifunctionPlotter.component';
import LargeFormatPrinterPlotter from './pages/largeFormatPrinterPlotter/largeFormatPrinterPlotter.component';
import Photocopier from './pages/photocopier/photocopier.component';
import Clients from './pages/clients/clients.component';

import ItSolutions from './pages/ItSolutions/ItSolutions.component';
import ProjectServerVirtualization from './pages/projectServerVirtualization/projectServerVirtualization.component';
import IpTransit from './pages/IpTransit/IpTransit.component';
import IpVpn from './pages/ipVpn/ipVpn.component';
import WifiService from './pages/wifiService/wifiService.component';

function App() {
  return (
    <div>
      <Header />
      {/* <Popup /> */}
      <div className="no-header-page">
        <Switch>
          <Route exact path='/' component={HomePage}/>
          <Route path='/service' component={Service}/>
          <Route path='/aboutUs' component={AboutUs}/>
          <Route path='/contactUs' component={ContactUs}/>
          <Route path='/clients' component={Clients}/>
          <Route path='/product' component={Product}/>
          <Route path='/APInvoicesDOScanning' component={APInvoicesDOScanning}/>
          <Route path='/BookScanning' component={BookScanning}/>
          <Route path='/PatientMedicalRecordScanning' component={PatientMedicalRecordScanning}/>
          <Route path='/LegalDocumentScanning' component={LegalDocumentScanning}/>
          <Route path='/DocumentImaging' component={DocumentImaging}/>
          <Route path='/MobileApplication' component={MobileApplication}/>
          <Route path='/ITHostingServices' component={ITHostingServices}/>
          <Route path='/OnsiteScanning' component={OnsiteScanning}/>
          <Route path='/QuestionnairePage' component={QuestionnairePage}/>
          <Route path='/documentScanner' component={DocumentScanner}/>
          <Route path='/bookScanner' component={BookScanner}/>
          <Route path='/largeFormatScanner' component={LargeFormatScanner}/>
          <Route path='/multifunctionPlotter' component={MultifunctionPlotter}/>
          <Route path='/largeFormatPrinterPlotter' component={LargeFormatPrinterPlotter}/>
          <Route path='/photocopier' component={Photocopier}/>

          <Route path='/ItSolutions' component={ItSolutions}/>
          <Route path='/ProjectServerVirtualization' component={ProjectServerVirtualization}/>
          <Route path='/IpTransit' component={IpTransit}/>
          <Route path='/IpVpn' component={IpVpn}/>
          <Route path='/WifiService' component={WifiService}/>

        </Switch>
        <Footer />
      </div>
    </div>
  );
}

export default App;
