import React from "react";
import { withRouter } from 'react-router-dom';

import Meriki from '../../assets/partnership/meriki.png';
import HKMA_post from '../../assets/partnership/hkma_post.jpg';
import HKIA from '../../assets/partnership/hkia.png';
import HKBN from '../../assets/partnership/hkbn.png';
import HIT from '../../assets/partnership/hit.png';

import './clients.styles.scss';

const Clients = () => (
    <div className="clients">
        <div className="container">
            <div className="clients-header">
                <h2 id="title">Our Clients</h2>
                <div id="line" />
            </div>
            <div className="content-wrapper">
                <div className="row">
                    <img id="hkbn-logo" src={HKBN} alt="HKBN" />
                    <img id="hkia-logo" src={HKIA} alt="HKIA" />
                </div>
                <div className="row">
                    <img id="hkma-logo" src={HKMA_post} alt="HKMA_post" />
                    <img id="meriki-logo" src={Meriki} alt="Meriki" />
                    <img id="hit-logo" src={HIT} alt="HIT" />
                </div>
            </div>
            <div className="break" />
        </div>
    </div>
)

export default withRouter(Clients);