import React from "react";
import Banner from '../../assets/Banner/PatientMedicalRecordScanning.png';

import './PatientMedicalRecordScanning.styles.scss';

const PatientMedicalRecordScanning = () => (
    <div className='PatientMedicalRecordScanning'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">Patient & Medical Record Scanning </span>
                    <p className="description">Terminus has a reputation for excellence in the document imaging and digitization services industry. While our document imaging facility is highly secure, we understand that some clients have specific needs when it comes to their records. For this reason, we’ve perfected our on-site scanning capabilities and practices. If you have documents that are extremely fragile or confidential records that cannot leave the premises, then we have an on-site scanning solution for you.</p>
                    <p className="description">Sometimes though, rules, legal regulations or company policies just don’t allow documents to be removed from their location. Our onsite scanning services are aimed at companies who simply cannot take their documents off site. Our team will come to your site and setup an efficient onsite scanning service that replicates our own scanning bureau. We bring the staff, the equipment and most importantly the processes and expertise. This is unlikely to be the most cost effective method of scanning an archive, but for many customers there are a number of benefits that make this worthwhile:</p>
                    <div className="break"/>
                    <li className="description">You can view the progress of the scanning as it progresses</li>
                    <li className="description">Documents are kept secure at your office at all times for ultimate peace of mind</li>
                    <li className="description">Your newly scanned documents are made immediately available for use</li>
                    <div className="break"/>
                    <span className="title">Benefits:</span>
                    <li className="description">We’ll implement a professional scanning environment with dedicated scanning team and equipment right at your location</li>
                    <li className="description">No need to worry about having to transport your confidential or fragile documents because we come to you</li>
                    <li className="description">Outsourcing scanning is more cost effective than hiring, training & purchasing equipment to do it in-house</li>
                    <li className="description">Our equipment and workflow procedures are designed to complete high volume scanning projects quickly & accurately</li>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default PatientMedicalRecordScanning;