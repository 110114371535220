import React from "react";
import Banner from '../../assets/contactUs_banner.png';
import ContactInfo from '../../component/contactInfo/contactInfo.component';
import { withRouter } from 'react-router-dom';
import Button from '../../assets/button-lf-scanning-services.png';
import { Link } from 'react-router-dom';

import './contactUs.styles.scss';

const ContactUs = () => (
    <div className="contactUs">
        <div className="container">
            <div className="banner">
                <img src={Banner} alt="" />
                <div className="break" />
            </div>
            <div className="wrap">
                <ContactInfo />
            </div>
            <div className="break" />
            <div style={{ textAlign: "center" }}>
                <Link to="/QuestionnairePage"><img src={Button} alt="" /></Link>
            </div>
            <div className="break" />
        </div>
    </div>
)

export default withRouter(ContactUs);