import React from 'react';
import Banner from '../../assets/Banner/projectServerVirtualization.png';
import BannerBottom from '../../assets/Banner/projectServerVirtualization_img.jpg';

import './projectServerVirtualization.styles.scss';

const ProjectServerVirtualization = () => {
    return (
        <div id='projectServerVirtualization'>
            <div id='container'>
                <div id="topImage">
                    <img src={Banner} alt="TopImage" />
                </div>
                <div id='content'>
                    <p className="description">We offers the best value IP Transit service in the market. Our service is powered by the most interconnected network in the world, where it offers high quality, scalable and affordable bandwidth. Our IP Transit Service allows customer to access the global Internet by utilizing GOIP’s international backbone network and GOIP’s interconnection agreements, in which it combine our wide choice of service locations with our outstanding connectivity to major access and content networks throughout the world.</p>
                    <div id='image-wrapper'>
                        <img id='banner' src={BannerBottom} alt='banner' />
                    </div>
                    <div className='break' />
                </div>
            </div>
        </div>
    )
}

export default ProjectServerVirtualization;