import React from 'react';
import Banner from '../../assets/request-quote-onlineadmag_2_orig.jpg';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import emailjs from 'emailjs-com';

import 'bootstrap/dist/css/bootstrap.css';
import './questionnaire.styles.scss';

class QuestionnairePage extends React.Component {
    constructor() {
        super()

        this.state = {
            first_name: '',
            last_name: '',
            phone_number: '',
            company_name: '',
            address_line1: '',
            address_line2: '',
            city: '',
            state: '',
            zip_code: '',
            country: '',
            email: '',
            loose_sheet: "No",
            enigneering_drawing: "No",
            book_manual_magazine: "No",
            photo: "No",
            negative_film: "No",
            color_requirement: '',
            ocr_applied: 'No',
            reconstruction: "No",
            return_or_destroy: '',
            A5: "No",
            A4: "No",
            A3: "No",
            A2: "No",
            A1: "No",
            A0: "No",
            estimate_num_of_page: '',
            folder_file: "No",
            stapler_binding: "No",
            doc_stored_loose_sheet: "No",
            tell_us: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange = e => {   this.setState({ [e.target.name]: e.target.value })  }

    async handleSubmit(e) {
        e.preventDefault()

        //const { name, email, message } = this.state

        //console.log(this.state)


        emailjs.sendForm('default_service', 'template_COyaoe4o', e.target, 'user_l84KLB2CAVMH47lICteiS')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            }).then(() => {
                alert("Email sent successfully! Thank you for your information.");
            });
    }

    render() {
        return (
            <div className='questionnaire'>
                <div className="container">
                    <div className="topImage">
                        <img src={Banner} alt="TopImage" />
                    </div>
                    <div className="content">
                        <p className="description">
                            Please complete the form below so we can provide you with your quotation.
                        </p>
                        <p className="description">
                            For additional guidance, please contact our team on 1800-888-011.
                        </p>

                        <div className="break-space" />

                        <p className="title">
                            Contact Information
                        </p>
                        <label className="form-label">
                            <span className="form-required">* </span>
                            Indicates required field
                        </label>

                        <div className="break-space" />

                        <Form onSubmit={this.handleSubmit} style={{ width: '500px' }}>
                            <FormGroup>
                                <Label for="name">
                                    Name
                                    <span className="form-required"> *</span>
                                </Label>
                                <div className="inline-group">
                                    <Input style={{ width: '47%' }}
                                        type="text"
                                        name="first_name"
                                        placeholder="First Name"
                                        required
                                        onChange={this.handleChange} />
                                    <Input style={{ width: '47%' }}
                                        type="text"
                                        name="last_name"
                                        placeholder="Last Name"
                                        required
                                        onChange={this.handleChange} />
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label for="phone-number">
                                    Phone number
                                    <span className="form-required"> *</span>
                                </Label>
                                <Input
                                    type="tel"
                                    name="phone_number"
                                    required
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="campanyName">
                                    Company Name
                                    <span className="form-required"> *</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="company_name"
                                    required
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">
                                    Address
                                    <span className="form-required"> *</span>
                                </Label>
                                <div >
                                    <Input
                                        className="input-group"
                                        type="text"
                                        name="address_line1"
                                        placeholder="Line 1"
                                        required
                                        onChange={this.handleChange} />
                                    <Input
                                        className="input-group"
                                        type="text"
                                        name="address_line2"
                                        placeholder="Line 2"
                                        required
                                        onChange={this.handleChange} />
                                    <div className="inline-group">
                                        <Input style={{ width: '47%', marginBottom: '10px' }}
                                            type="text"
                                            name="city"
                                            placeholder="City"
                                            required
                                            onChange={this.handleChange} />
                                        <Input style={{ width: '47%', marginBottom: '10px' }}
                                            type="text"
                                            name="state"
                                            placeholder="State"
                                            required
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="inline-group">
                                        <Input style={{ width: '47%', marginBottom: '10px' }}
                                            type="text"
                                            name="zip_code"
                                            placeholder="Zip Code"
                                            onChange={this.handleChange} />
                                        <Input style={{ width: '47%', marginBottom: '10px' }}
                                            type="text"
                                            name="country"
                                            placeholder="Country"
                                            onChange={this.handleChange} />
                                    </div>

                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">
                                    Email
                                    <span className="form-required"> *</span>
                                </Label>
                                <Input
                                    type="eamil"
                                    name="email"
                                    required
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <FormGroup className="checkbox-form">
                                <Label for="typeOfDocument">
                                    Type of document
                                </Label>
                                <Label for="typeOfDocument" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="loose_sheet"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Loose Sheet (Printed Paper, Invoice, Receipt)</span>
                                </Label>
                                <Label for="typeOfDocument" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="enigneering_drawing"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Engineering Drawing</span>
                                </Label>
                                <Label for="typeOfDocument" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="book_manual_magazine"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Book / Manual / Magazine</span>
                                </Label>
                                <Label for="typeOfDocument" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="photo"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Photo</span>
                                </Label>
                                <Label for="typeOfDocument" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="negative_film"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Negative Film</span>
                                </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label for="colorRequirement">
                                    Color Requirement
                                </Label>
                                <Input
                                    type="select"
                                    name="color_requirement"
                                    required
                                    onChange={this.handleChange}>
                                    <option disabled selected>Please Select</option>
                                    <option value="Black & White">Black & White</option>
                                    <option value="Color">Color</option>
                                    <option value="Color pages in color, remaining in B&W">Color pages in color, remaining in B&W</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="ocr_applied">
                                    Would you like OCR Applied to your Scanned Images?
                                    <span className="form-required"> *</span>
                                </Label>
                                <FormGroup check className="radio-row">
                                    <Label check>
                                        <Input type="radio" name="ocr_applied" value="Yes" onChange={this.handleChange} required />
                                        <span>Yes</span>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="radio-row">
                                    <Label check>
                                        <Input type="radio" name="ocr_applied" value="No" onChange={this.handleChange} required />
                                        <span>No</span>
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="colorRequirement">
                                    Reconstruction - Do you require your documents to be rebound & Returned once scanning has been completed?
                                    <span className="form-required"> *</span>
                                </Label>
                                <FormGroup check className="radio-row">
                                    <Label check>
                                        <Input type="radio" name="reconstruction" value="Yes" onChange={this.handleChange} required />{' '}
                                        <span>Yes</span>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="radio-row">
                                    <Label check>
                                        <Input type="radio" name="reconstruction" value="No" onChange={this.handleChange} required />{' '}
                                        <span>No</span>
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="ReturnDestroyDocumentsOnceScanned">
                                    Return / Destroy Documents Once Scanned
                                </Label>
                                <Input
                                    type="select"
                                    name="return_or_destroy"
                                    onChange={this.handleChange}>
                                    <option disabled selected>Please Select</option>
                                    <option value="Secure Commercial Destruction - Shred, Pulp, Recycle" >Secure Commercial Destruction - Shred, Pulp, Recycle</option>
                                    <option value="Return" >Return</option>
                                </Input>
                            </FormGroup>

                            <FormGroup className="checkbox-form">
                                <Label for="paperSize">
                                    Paper Size
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A5"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A5</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A4"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A4</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A3"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A3</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A2"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A2</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A1"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A1</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="A0"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>A0</span>
                                </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label for="EstimateNumberOfPagesNeedToScan">
                                    Estimate Number Of Pages Need To Scan
                                    <span className="form-required"> *</span>
                                </Label>
                                <Input
                                    type="number"
                                    name="estimate_num_of_page"
                                    required
                                    onChange={this.handleChange} />
                            </FormGroup>


                            <FormGroup className="checkbox-form">
                                <Label for="HowAreDocumentsStored">
                                    How are documents stored?
                                </Label>
                                <Label for="HowAreDocumentsStored" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="folder_file"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Folder / File</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="stapler_binding"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Stapler / Binding</span>
                                </Label>
                                <Label for="paperSize" className="checkbox-row">
                                    <Input
                                        type="checkbox"
                                        name="doc_stored_loose_sheet"
                                        value="Yes"
                                        onChange={this.handleChange} />
                                    <span>Loose Sheet</span>
                                </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label for="TellUsMoreOnYourRequirement">
                                    Tell us more on your requirement
                                    <span className="form-required"> *</span>
                                </Label>
                                <Input
                                    style={{ height: '200px' }}
                                    type="textarea"
                                    name="tell_us"
                                    required
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    color="danger"
                                    size="lg">
                                    Submit
                                </Button>
                            </div>

                        </Form>



                    </div>
                </div>
            </div >
        );
    }
}

export default QuestionnairePage;