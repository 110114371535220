import React from "react";
import ProductList from '../../component/productList/productList.component';
import { withRouter } from 'react-router-dom';

import './photocopier.styles.scss';

const Photocopier = () => (
    <div className="photocopier">
        <div className='break' />
        <div className='container'>
            <ProductList />
            <div className='right'>
                <span className='title'>
                    Photocopier
                </span>
            </div>
        </div>
        <div className='break' />
    </div>
)

export default withRouter(Photocopier);