import React from "react";
import ProductList from '../../component/productList/productList.component';
import { withRouter } from 'react-router-dom';

import './bookScanner.styles.scss';

const BookScanner = () => (
    <div className="bookScanner">
        <div className='break'/>
        <div className='container'>
            <ProductList />
            <div className='right'>
                <span className='title'>
                Book Scanner
            </span>
            </div>
        </div>
        <div className='break'/>
    </div>
)

export default withRouter(BookScanner);