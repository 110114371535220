import React from 'react';

import Banner from '../../assets/Banner/ipvpn.png';
import BannerBottom from '../../assets/Banner/ipvpn_img.jpg';

import './ipVpn.styles.scss';

const IpVpn = () => {
    return (
        <div id='ipvpn'>
            <div id='container'>
                <div id="topImage">
                    <img src={Banner} alt="TopImage" />
                </div>
                <div id='content'>
                <img id='banner' src={BannerBottom} alt='banner' />
                    <div id='content-right'>
                        <div className="intro">
                            <p className="description"><span className='title'>IP-VPN</span> involves creating a virtual private network based on existing networks, which can then be used to link different locations. In effect, you ‘build’ a kind of tunnel between two endpoints. This arrangement uses a private IP network, which is managed by a single provider.</p>
                            <p className="description">Even though several customers use this physical network (via MPLS), you still have access to a ‘private network’ because the traffic flows are strictly separated using IP subnets.</p>
                            <p className="description">One possible disadvantage of IP-VPN is that a Quality of Service has to be configured in many cases, particularly when the quality of the different connections is not the same.</p>
                            <p className="description">An IP-VPN requires a synchronous connection: the upload and download speeds must be the same. This is to prevent malfunctions and/or delays from slowing down work processes within an organization. In addition, you always have to involve the provider, for example when you want to add new IP subnets to a network.</p>
                        </div>
                    </div>
                    <div className='break' />
                </div>
            </div>
        </div>
    )
}

export default IpVpn