import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 22.320328,
      lng: 114.168036
    },
    zoom: 17
  };

  renderMarker = (map, maps) => {
    new maps.Marker({
      map: map,
      position: new maps.LatLng(22.320328, 114.168036),
      title: 'Empire'
    });
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBi722GTUQJjyWCO2ZUybJLMqaRKo2LYf0"}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.renderMarker(map, maps)}
        >
        </GoogleMapReact>
      </div>

        

    );
  }
}

export default GoogleMap;