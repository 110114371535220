import React from 'react';
import '../aboutUs/aboutUs.styles.scss';
import ServiceList from '../../component/serviceList/serviceList.component';
import {withRouter} from 'react-router-dom';


const AboutUs = () => (
    <div className="aboutUs">
        <div className="container">
            <div className="description">
                <h2>Terminus Development Limited </h2>
                <p>Terminus Development Limited is a leading provider of integrated solutions in data collection which cover digitize services, supply of document scanners and document management software. We have expert team of experienced sales and technical staffs to help you realize the benefit of an effective document management solution that allow you to take control of your documents.</p>
                <p>Terminus has developed a range of document and information management delivery solutions and services which help our clients to more efficiently manage their business information and associated processes.</p>
                <p>Terminus brings a combined set of document management products and services to market which address the specific functional and business sector requirements such as Government, Education and Service providers.</p>

                <div className="break" />
                <ServiceList />
                <div className="break" />

            </div>
        </div>
    </div>
)

export default withRouter(AboutUs);