import React from 'react';
import './service.styles.scss';
import {withRouter} from 'react-router-dom';
import ServiceList from '../../component/serviceList/serviceList.component';

const Service = () => (
    <div className="service">
        <div className="container">
            <div className="description">
                <h2>Document Scanning</h2>
                <p>Scanning of forms, capturing written data and converting them into digital information for back end databases will improve efficiency and reduce processing time.</p>
                <h2>Enterprise Content Management</h2>
                <p>Sure-Reach provides implementation services for Enterprise Content Management in your office if you wish to handle your own management of information internally.</p>
                <h2>Secured Shredding & Destruction</h2>
                <p>In this era of globalisation, privacy and confidentiality is more important than ever before. The secure disposal of your sensitive business information, main data and material is vital. The Privacy Act requires many businesses to ensure the confidentiality of personal information.</p>
                <h2>Hosting Services</h2>
                <p>Sure-Reach provides storage space in terabytes or gigabytes for your scanned images and digital documents, with proper indexing for easy retrieval.</p>

                <div className="break" />
                <ServiceList />
                <div className="break" />
            </div>
        </div>
    </div>
)


export default withRouter(Service);