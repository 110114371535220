import React from 'react';
import { Link } from 'react-router-dom';
import './productList.styles.scss';
//import MENU_BAR_DATA from '../../data/products/menuItem.json';
import MENU_BAR_DATA from '../../data/header/menuBar.json';

class ProductList extends React.Component {
    constructor() {
        super();

        this.state = {
            menuBarData: MENU_BAR_DATA
        }
    }

    render() {
        const { menuBarData } = this.state;

        return (
            <div className="left">
                <div className="menu">
                    <div className="name">
                        <span>PRODUCTS</span>
                    </div>
                    <div className="menu-item">
                        {
                            menuBarData.menuBar[2].subMenuBar.map(
                                ({ id, linkUrl, name }) =>
                                    (<Link
                                        to={linkUrl}
                                        className="items"
                                    >
                                        {name}
                                    </Link>)
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductList;