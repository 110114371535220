import React from "react";
import Banner from '../../assets/Banner/LegalDocumentScanning.png';

import './LegalDocumentScanning.styles.scss';

const LegalDocumentScanning = () => (
    <div className='LegalDocumentScanning'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">Legal Document Scanning</span>
                    <p className="description">A specialist document scanning service for the legal sector. Law firms have always produced a significant amount of paperwork – it’s something that simply comes with the profession. The sheer volume of paperwork involved in just one major case can be in the thousands of pages. No matter what type of law you practice, it’s critical that your firm has proper document management- and the labor and time it takes to manage a roomful of files can create unnecessary costs. Documents for litigation can be managed much more efficiently through digital document management. Not only do you have instant access to editable files, you can easily code, distribute and secure every piece of evidence, courtroom transcript, and every document in the discovery process quickly and easily.</p>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default LegalDocumentScanning;