import React from 'react';
import './contactInfo.styles.scss';
import GoogleMap from '../../component/googleMap/googleMap.component';

const ContactInfo = () => (
    <div>
        <div className="content">
            <div className="contain-left">
                <div className="details">
                    <p>Hong Kong</p>
                    <p>RM A, 21/F, MONG KOK, HARBOUR CENTER, 638 SHANGHAI ST, MONG KOK, KL.</p>
                    <p>Opening Hour: Monday - Friday.
                        <br />
                        1000 to 1800
                        <br />
                        Lunch Break: 1300 to 1400
                </p>
                </div>
            </div>
            <div className="contain-right">
                <div className="map">
                    <GoogleMap />
                </div>
            </div>
        </div>
        <hr />
    </div>
)

export default ContactInfo;