import React from "react";
import Banner from '../../assets/Banner/InvoiceScanning.png';

import './APInvoicesDOScanning.styles.scss';

const APInvoicesDOScanning = () => (
    <div className='APInvoicesDOScanning'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">AP  Invoices & DO Scanning</span>
                    <p className="description">Our Invoice Scanning Service will have an immediate positive impact on any business looking to improve the way they handle and process invoices. Increase processing speeds, reduce staffing costs and lever early payment discounts by becoming more efficient in the way you process your invoices.</p>
                    <p className="description">Using our sophisticated data capture techniques we save our customers, time, money and significant effort by removing the need to manually process accounts payable invoices. This frees up your time to concentrate on core accounts payable tasks in your department Our invoice scanning services include scanning, capture, processing, approval and payment. Traditional methods of capturing data from invoices, purchase orders and other financial documents can be very time consuming.  Inaccurate data entry can cause problems in accountancy packages and waste staff time through lengthy verification and approval procedures. Our invoice scanning service provides accurate data capture for line item descriptions, supplier name, number and amount details.</p>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default APInvoicesDOScanning;