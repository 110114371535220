import React from "react";
import ProductBanner from '../../assets/Banner/productBanner.png';
import MENU_BAR_DATA from '../../data/homepage/menuItem.json';
import './product.styles.scss';
import MiddleButton from '../../assets/homepage_middle_button.png';
import ProductIcon from '../../assets/productIcon.png';
import S2080wScanner from '../../assets/S2080wScanner.png';
import I3000Series from '../../assets/I3000Series.png';
import { withRouter } from 'react-router-dom';
import ProductList from '../../component/productList/productList.component';

class Product extends React.Component {
    constructor() {
        super();

        this.state = {
            menuBarData: MENU_BAR_DATA
        }
    }

    render() {
        return (
            <div className='product'>
                <div className="container">
                    <div className="topImage">
                        <img src={ProductBanner} alt="TopImage" />
                    </div>
                    <div className="content">
                        <ProductList />
                        <div className="right">
                            <div className="intro">
                                <span className="title">What is Passport Scanner?</span>
                                <p className="description">Passport scanner is specially developed for scan passport, nric mykad (malaysia ic), other ID card.</p>
                                <p className="description">Capture passport or nric card data and transfer to any third party software, such as hotel check-in or guest check-in software.</p>
                                <p className="description">Built-in directly with MRTD Reader software.</p>
                                <p className="description">It has 5 megapixels optical resolution and support windows XP to 10.</p>
                                <span className="title">​Who needs Passport Scanner?</span>
                                <p className="description">Passport scanner is suitable for Hospitality, Immigration and Hotel.</p>
                            </div>
                            <div className="middle-button">
                                <div className="break" />
                                <a href="https://wa.me/85267488861" target="_blank" rel="noopener noreferrer">
                                    <img src={MiddleButton} alt="" />
                                </a>
                                <div className="break" />
                            </div>

                            <div className="productList">
                                <div className="product">
                                    <div className="product-image-container">
                                        <img src={S2080wScanner} alt="" />
                                    </div>
                                    <span>S2000 Series</span>
                                </div>
                                <div className="product">
                                    <div className="product-image-container">
                                        <img src={I3000Series} alt="" />
                                    </div>
                                    <span>I 3000 Series</span>
                                </div>
                                <div className="product">
                                    <div className="product-image-container">
                                        <img src={ProductIcon} alt="" />
                                    </div>
                                    <span>Automatic Sensor Detection</span>
                                </div>
                                <div className="product">
                                    <div className="product-image-container">
                                        <img src={ProductIcon} alt="" />
                                    </div>
                                    <span>Infrared and UV sensor</span>
                                </div>
                            </div>
                            <div className="break" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Product);