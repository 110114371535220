import React from "react";
import Banner from '../../assets/Banner/BookScanning.png';

import './BookScanning.styles.scss';

const BookScanning = () => (
    <div className='BookScanning'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">Book Scanning</span>
                    <p className="description">Using the latest commercial book scanning technology – Terminus provide a fast and high quality book scanning service. Quality, speed, accuracy and security are the cornerstone of our business. We won’t be beaten on price.We provide book scanning services for Malaysia and Singapore.</p>
                    <div className="break"/>
                    <li className="description">Almost any size book can be scanned right up to A3</li>
                    <li className="description">Colour or black and white</li>
                    <li className="description">Almost any type of book can be scanned from a small paperback right up to a large hardback reference book.</li>
                    <li className="description">High volume is no problem. Our multi skilled staff are cross trained to work on almost any project in the scanning bureau.</li>
                    <li className="description">Digital image formats include PDF, JPG, TIFF, BMP and RAW</li>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default BookScanning;