import React from 'react';
import { Link } from 'react-router-dom';
import '../serviceList/serviceList.styles.scss';

//import BookScanning from '../../assets/serviceIcon/BookScanning.png';
//import ScanOnDemand from '../../assets/serviceIcon/ScanOnDemand.png';
import OnsiteScanning from '../../assets/serviceIcon/onsiteScanning.jpg';
//import PatientMedicalRecordScanning from '../../assets/serviceIcon/PatientMedicalRecordScanning.png';
//import APInvoicesDOScanning from '../../assets/serviceIcon/APInvoicesDOScanning.png';
//import LegalDocumentScanning from '../../assets/serviceIcon/LegalDocumentScanning.png';
//import BulkDocumentScanning from '../../assets/serviceIcon/BulkDocumentScanning.png';
import DocumentImaging from '../../assets/serviceIcon/DocumentImaging.jpg';
//import DocumentArchivingCenter from '../../assets/serviceIcon/DocumentArchivingCenter.png';
//import BacklogConversion from '../../assets/serviceIcon/BacklogConversion.png';
//import ITHostingServices from '../../assets/serviceIcon/IT&HostingServices.png';
import MobileApplication from '../../assets/serviceIcon/MobileApplication.jpg';
import WifiService from '../../assets/serviceIcon/wifiservice.jpg';
import IPVPN from '../../assets/serviceIcon/ipvpn.jpg';
import IpTransit from '../../assets/serviceIcon/ipTransitToAndFromMainlandChina.jpg';
import ProjectServerVirtualization from '../../assets/serviceIcon/projectServerVirtualization.jpg';
import ItSolutions from '../../assets/serviceIcon/itSolutions.jpg';

const ServiceList = () => (
    <div className="serviceList">
        <div className="wrap">
            <div className="block">
                <img src={ItSolutions} alt="" />
                <div className="title">
                    <p><Link to="/ItSolutions">IT Solutions</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={OnsiteScanning} alt="" />
                <div className="title">
                    <p><Link to="/OnsiteScanning">Onsite Scanning</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={ProjectServerVirtualization} alt="" />
                <div className="title">
                    <p><Link to="/ProjectServerVirtualization">Project Server Virtualization</Link></p>
                    {/* <p><Link to="/PatientMedicalRecordScanning">Patient & Medical Record Scanning</Link></p> */}
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={IpTransit} alt="" />
                <div className="title">
                    <p><Link to="/IpTransit">IP transit to and from mainland China</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={IPVPN} alt="" />
                <div className="title">
                    {/* <p><Link to="/LegalDocumentScanning">Legal Document Scanning</Link></p> */}
                    <p><Link to="/IpVpn">IP VPN</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={DocumentImaging} alt="" />
                <div className="title">
                    <p><Link to="/DocumentImaging">Document Imaging</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={MobileApplication} alt="" />
                <div className="title">
                    <p><Link to="/MobileApplication"> Mobile Application</Link></p>
                </div>
            </div>
        </div>
        <div className="wrap">
            <div className="block">
                <img src={WifiService} alt="" />
                <div className="title">
                    {/* <p><Link to="/ITHostingServices">IT & Hosting Services</Link></p> */}
                    <p><Link to="/WifiService">Wifi Service</Link></p>
                </div>
            </div>
        </div>
    </div>
)

export default ServiceList;