import React from "react";
import MENU_BAR_DATA from '../../data/header/menuBar.json';
import MenuItem from '../menuItem/menuItem.component';
import { Link } from 'react-router-dom';
import './header.styles.scss';
import HomeIcon from '../../assets/HomeIcon.png';


class Header extends React.Component {
    constructor() {
        super();

        this.state = {
            menuBarData: MENU_BAR_DATA,
            selectedMenuItem: '',
            selectedMenuItemID: 2,
            isDisplaySubMenu: false,
            isShrink: false
            //selectedMenuItem: null
        }
    }

    handleScroll = () => {
        if (window.pageYOffset > 100) {
            this.setState({isShrink: true});          // do something at end of scroll
        }else{
            this.setState({isShrink: false});
        }
    }

    selectMenuItem = (name, number, subMenuBar) => {
        const { selectedMenuItem , isDisplaySubMenu }= this.state;

        if(selectedMenuItem === "Products" && isDisplaySubMenu === false ){
            this.setState({ selectedMenuItem: name });
            //this.setState({ selectedMenuItemID: number });
            this.setState({isDisplaySubMenu: true});
        }
        else if (name === selectedMenuItem || subMenuBar === '') {
            this.setState({ selectedMenuItem: name });
            this.setState({isDisplaySubMenu: false});
        }
        else{
            this.setState({ selectedMenuItem: name});
            this.setState({ selectedMenuItemID: number });
            this.setState({isDisplaySubMenu: true});
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
      }
      
      componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }

    render() {
        const { menuBarData, selectedMenuItemID,selectedMenuItem, isDisplaySubMenu, isShrink } = this.state;

        return (
            <div className={`${isShrink ? "scroll-down " : "scroll-up"} menuBar`} onScroll={this.handleScroll}>
                <div className="container">
                    <div className="header-inner-wrap">
                        <div className="icon">
                            <Link to="/"><img src={HomeIcon} alt=""/></Link>
                        </div>
                        <div className="nav-wrap">
                            {
                                menuBarData.menuBar.map(
                                    ({ id, ...othersProps }) =>
                                        (<MenuItem key={id} selectMenuItem={this.selectMenuItem} selectedMenuItem={selectedMenuItem} isDisplaySubMenu={isDisplaySubMenu} {...othersProps} />)
                                )
                            }
                        </div>
                    </div>
                    <div className="header-inner-wrap-sub">
                        <div className={`${isShrink ? "" : "nav-wrap-sub"}`}>
                            {
                                !isDisplaySubMenu ?
                                null
                                :
                                menuBarData.menuBar[selectedMenuItemID-1].subMenuBar.map(
                                    ({ id, ...othersProps }) =>
                                        (<MenuItem key={id} selectMenuItem={this.selectMenuItem} selectedMenuItem={selectedMenuItem} isDisplaySubMenu={isDisplaySubMenu} {...othersProps} />)
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;