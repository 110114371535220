import React from 'react';
import Banner from '../../assets/Banner/ipTransit.jpg';
import BannerBottom from '../../assets/Banner/ipTransit_img.jpg';

import './IpTransit.styles.scss';

const IpTransit = () => {
    return (
        <div id='iptransit'>
            <div id='container'>
                <div id="topImage">
                    <img src={Banner} alt="TopImage" />
                </div>
                <div className="intro">
                    <p className="description">We provides high-speed internet access with a committed rate and flexible internet access connected with multiple Tier-1 providers in Greater China and worldwide to suit for your business needs. Ethernet has become the reigning standard in Local Area Networks (LAN). Our Ethernet WAN offers un-contended Internet bandwidth that no other business will share.</p>
                </div>
                <div id='content'>
                    <div id='content-left'>
                            <p className="description">We offers the best value IP Transit service in the market. Our service is powered by the most interconnected network in the world, where it offers high quality, scalable and affordable bandwidth. Our IP Transit Service allows customer to access the global Internet by utilizing GOIP’s international backbone network and GOIP’s interconnection agreements, in which it combine our wide choice of service locations with our outstanding connectivity to major access and content networks throughout the world.</p>
                    </div>
                    <img id='banner' src={BannerBottom} alt='banner' />
                    <div className='break' />
                </div>
            </div>
        </div>
    )
}

export default IpTransit;