import React from 'react';
import GoogleMap from '../googleMap/googleMap.component';
import facebookSmallIcon from '../../assets/facebook_small_icon.png';
import youtubeSmallIcon from '../../assets/youtube_small_icon.png';
import instagramSmallIcon from '../../assets/instagram_small_icon.png';
import whatsappIcon from '../../assets/whatsapp_icon.png';

import './footer.styles.scss';

class Footer extends React.Component {
    constructor() {
        super();

        this.state = {

        }
    }

    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="content">
                        <div className="upper">
                            <div className="location">
                                <div className="item">
                                    <h2>Location</h2>
                                </div>
                                <GoogleMap className="map" />
                            </div>
                            <div className="contactUs">
                                <div className=" item">
                                    <h2>Contact Us</h2>
                                </div>
                                <blockquote>
                                    <span>Rm A, 21/F Mong Kok Harbour</span> 
                                    <span>Center , 638 Shang Hai St</span>
                                    <span>Mong Kok Kl</span>
                                </blockquote>
                                <a href="https://wa.me/85267488861" target="_blank" rel="noopener noreferrer">
                                    <img src={whatsappIcon} alt="whatsappIcon" />
                                </a>
                            </div>

                            <div className="upcomingEvent item">
                                <h2>Upcoming Event</h2>
                            </div>
                            <div className="socialMedia">
                                <img src={facebookSmallIcon} alt="" />
                                <img src={youtubeSmallIcon} alt=""/>
                                <img src={instagramSmallIcon} alt="" />
                            </div>
                        </div>
                        { /* <div className="lower">
                            <h2>COPYRIGHT © 2019 SCANEXT SOLUTION SDN BHD</h2>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;