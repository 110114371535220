import React from "react";
import Banner from '../../assets/homepage_banner.jpg';

import './homePage.styles.scss';
import MiddleButton from '../../assets/homepage_middle_button.png';
import ContactInfo from '../../component/contactInfo/contactInfo.component';

const HomePage = () => (
    <div className='homepage'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="right">
                    <div className="intro">
                        <span className="title">Express to Document Management Productivity</span>
                        <p className="description">Whether you’re looking to boost productivity, departmental efficiency, or document workflows, Terminus has the scanning, printing and conversion solutions that can boost worker productivity, provide greater business-process efficiency. Terminus is dedicated to providing quality services to stop the inefficiency inherent in paper files and deliver greater productivity, better information management, and lower overhead costs.</p>
                        <span className="title">Dedicated Performance for Dedicated use</span>
                        <p className="description">Terminus can provide you the latest technology in imaging and printing equipment to streamline your organization's scanning and printing solutions with our line-up of award winning machines. Terminus has the expertise and advanced imaging equipments available to provide a dedicated solution for all your imaging needs from bulk records digitization to micrographic conversion.</p>
                    </div>
                    <div className="middle-button">
                        <div className="break" />
                        <a href="https://wa.me/85267488861" target="_blank" rel="noopener noreferrer">
                            <img src={MiddleButton} alt="" />
                        </a>

                        <div className="break" />
                    </div>

                    <ContactInfo />
                    <div className="break" />
                </div>
            </div>
        </div>
    </div>
)

export default HomePage;