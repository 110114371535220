import React from 'react';

import Banner from '../../assets/Banner/WifiServiceBanner.png';
import BannerBottom from '../../assets/Banner/wifiService_img.jpg';

import './wifiService.styles.scss';

const WifiService = () => {
    return (
        <div id='WifiService'>
            <div id='container'>
                <div id="topImage">
                    <img src={Banner} alt="TopImage" />
                </div>
                <div id='content'>
                    <div id='content-left'>
                        <div className="intro">
                            <span className="title">Wifi-Solution </span>
                            <p className="description">Wifi has become a fundamental infrastructure in our society. LWTI provides a series of solution to fit for different technical requirements of company. Based on our experience in WiFi projects, we are able to handle various requirements in cover area, signal rate, security, application, authentication and accounting.</p>
                            <span className="title">Our Services </span>
                            <p>1. 電腦網絡佈線/拉線工程(Cat5e/Cat6)</p>
                            <p>2. Optical fiber network wiring project</p>
                            <p>3. Network wiring project across floors or across units</p>
                            <p>4. Wi-Fi network installation and setup (including establishment of guest or employee network)</p>
                            <p>5. Patch Panel, Mounting and computer cabinet management</p>
                            <p>6. Network re-infrastructure</p>
                        </div>
                    </div>
                    <img id='banner' src={BannerBottom} alt='banner' />
                    <div className='break' />
                </div>
            </div>
        </div>
    )
}

export default WifiService;