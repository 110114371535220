import React from "react";
import Banner from '../../assets/Banner/MobileApplication.png';

import './MobileApplication.styles.scss';

const MobileApplication = () => (
    <div className='MobileApplication'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">IOS Web APPLICATION </span>
                    <p className="description">Leading iOS app development company building iOS mobile applications since 2017. We offer iOS app development to create native iPhone and iPad apps. Our iOS app developers have created many successful iPhone and iPad apps for companies across the HK AND CHINA</p>
                </div>
                <div className="intro">
                    <span className="title">Android App Development </span>
                    <p className="description">The Android ecosystem is complex compared with iOS. There are numerous manufacturers producing Android-based devices. The range and capabilities of these devices vary greatly. This makes it complicated to create and support Android apps. TERMINUS has been a leading Android app developer since 2010. Over the years, our Android app developers have created many Android phone and tablet applications</p>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default MobileApplication;