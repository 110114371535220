import React from "react";
import Banner from '../../assets/Banner/DocumentImaging.png';

import './DocumentImaging.styles.scss';

const DocumentImaging = () => (
    <div className='DocumentImaging'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">Document Imaging</span>
                    <p className="description">Terminus provide Best Services for Document Imaging, empowering your organizational processes by converting documents into digital assets. Our services facilitate instant and secure access to information, enabling increased productivity, performance and profits. Terminus can converts paper documents to digital images and enables you to store, access and manage them electronically by using the Document Imaging Service from us. Terminus stand for quality, speed, accuracy and security. We are recognized experts in providing document imaging services to support large scale, for projects that require quick turnarounds and high quality deliverables.</p>
                    <p className="description">Terminus also can provide your organization with the highest document imaging quality and coded data available in the industry today, enabling quick search and accurate retrieval of each file. Terminus will achieve this by tailoring our services to meet the unique requirements of each project. Our clients realize tangible benefits that reduce their total file management expense and increase profits by optimizing their business processes. Businesses today are constantly creating paper documents which often add to already existing paper archives. Most businesses understand that the future is digital and a paper light or paperless environment should be worked towards. Many documents need to be stored for compliance purposes however traditional box storage or paper based records management no longer proves to be a cost effective or efficient way to work.</p>
                    <p className="description">One of the biggest challenges for businesses of any size is how to bring their existing archives together with the information they create on a daily basis. Terminus Document Imaging Services allow organisations to convert all of their hard copy document archives into a digital format. The images can be saved into a variety of formats (often PDF, TIFF and JPG) and uploaded into almost any content management system. This makes for easy searching, fast retrieval, improved sharing and security.</p>
                    <div className="break"/>
                </div>
            </div>
        </div>
    </div>
)

export default DocumentImaging;