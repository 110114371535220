import React from "react";
import ProductList from '../../component/productList/productList.component';
import { withRouter } from 'react-router-dom';

import './largeFormatPrinterPlotter.styles.scss';

const LargeFormatPrinterPlotter = () => (
    <div className="multifunctionPlotter">
        <div className='break' />
        <div className='container'>
            <ProductList />
            <div className='right'>
                <span className='title'>
                    Large Format Printer / Plotter
                </span>
            </div>
        </div>
        <div className='break' />
    </div>
)

export default withRouter(LargeFormatPrinterPlotter);