import React from "react";
import ProductList from '../../component/productList/productList.component';
import { withRouter } from 'react-router-dom';

import './largeFormatScanner.styles.scss';

const LargeFormatScanner = () => (
    <div className="largeFormatScanner">
        <div className='break' />
        <div className='container'>
            <ProductList />
            <div className='right'>
                <span className='title'>
                    Large Format Scanner
                </span>
            </div>
        </div>
        <div className='break' />
    </div>
)

export default withRouter(LargeFormatScanner);