import React from 'react';
import { Link } from 'react-router-dom';
import './menuItem.styles.scss';

const MenuItem = ({name, number, subMenuBar, selectedMenuItem, selectMenuItem, isDisplaySubMenu, linkUrl}) => (
    <div className='menu-item'>
        {
            subMenuBar === "" ?
            <Link 
            to={linkUrl}
            onClick={() => selectMenuItem(name, number, subMenuBar)}
            className={`${selectedMenuItem === name ? "selected" : "options"}`}
            >
            {name}
            </Link>
            :
            selectedMenuItem === name && isDisplaySubMenu === true ?
            <p
            onClick = {() => selectMenuItem(name, number, subMenuBar)}
            className={`${selectedMenuItem === name ? "selected" : "options"}`}
            >
            {name}-
            </p>
            :
            <Link 
            to={linkUrl}
            onClick={() => selectMenuItem(name, number, subMenuBar)}
            className={`${selectedMenuItem === name ? "selected" : "options"}`}
            >
            <p
            onClick = {() => selectMenuItem(name, number, '')}
            >
            {name}+
            </p>
            </Link>
        }

    </div>
)

export default MenuItem;