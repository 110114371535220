import React from "react";
import ProductList from '../../component/productList/productList.component';
import { withRouter } from 'react-router-dom';

import './DocumentScanner.styles.scss';

const DocumentScanner = () => (
    <div className="documentScanner">
        <div className='break'/>
        <div className='container'>
            <ProductList />
            <div className='right'>
                <span className='title'>
                    Document Scanner
            </span>
            </div>
        </div>
        <div className='break'/>
    </div>
)

export default withRouter(DocumentScanner);