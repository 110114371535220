import React from "react";
import Banner from '../../assets/Banner/IT&HostingServices.png';

import './IT&HostingServices.styles.scss';

const ITHostingServices = () => (
    <div className='ITHostingServices'>
        <div className="container">
            <div className="topImage">
                <img src={Banner} alt="TopImage" />
            </div>
            <div className="content">
                <div className="intro">
                    <span className="title">IT & Hosting Services </span>
                    <p className="description">We have a team of expertise covering comprehensive IT services and specialized in accomplishing various software developments and supports. We provide experienced staff various from Analyst Programmer, System Analyst, Technical Support, Technical Consultant and Project Manager to customers under short to long term arrangements. In order to meet various project needs, our staffs are also prepared to work on-site at client's offices to support and to facilitate our client’s system development process.</p>
                    <p className="description">Providing private cloud services for enterprises based on our data center business, including a series of functions such as elastic computing, cloud storage, SDN/NFV network, cloud data base, big data and security services, to create a more convenient and stable IT system environment for enterprises.</p>
                </div>
            </div>
        </div>
    </div>
)

export default ITHostingServices;